import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { Banner, Layout, SubHeader } from 'components/global'
import { media } from 'utils/media'

const Body = styled.section`
  width: 100%;

  position: relative;
  background: var(--white);
  color: var(--black);
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  @media ${media.xl} {
    padding-top: 3rem;
  }
  .video {
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
  }

  .body {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 99;
    p {
      margin-bottom: 1rem;
      @media ${media.xl} {
        font-size: 19px;
      }
    }
    .text {
      margin: 4rem 0;
    }
    .image {
      width: 100%;
      height: auto;
      margin: 2rem 0;
      /* .gatsby-image-wrapper {
        width: 100%;
        height: auto;
        img {
          object-fit: fill;
        }
      } */
    }
  }
`

const FinalPage = () => {
  const i = true
  return (
    <Layout>
      <SubHeader title="Arnold Clark Cup 2022" />
      <Body>
        <div className="body">
          <div className="image">
            <StaticImage
              src="../images/close/AC-Cup-Overview-2022.png"
              alt="2022 Stats for Arnold Clark Cup"
            />
          </div>
        </div>
      </Body>
      <Banner />
    </Layout>
  )
}

export default FinalPage
